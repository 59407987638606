import { useState, useEffect } from 'react';
import api from 'utils/api';

const useSubmit = (url, method) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setError] = useState('');
  const [response, setResponse] = useState(null);

  const submit = (body) => {
    setError('');
    setResponse(null);
    setIsSubmitting(true);
    api[method](url, body)
      .then(function (resp) {
        if (resp?.data?.ResultCode === 1) {
          setResponse(resp?.data?.Data);
          setError('');
        } else if (resp?.data?.Result === 'Error') {
          setError(resp?.data?.Error);
          setResponse(null);
        } else {
          setError('Unknown error');
          setResponse(null);
        }
      })
      .catch(function (err) {
        setError(err.response);
        setResponse(null);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return { response, isSubmitting, submitError, submit };
};

export default useSubmit;