import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import "assets/css/image-tiles.css";
import ConfirmationOverlay from 'components/ConfirmationOverlay';
import Modal from 'components/Modal';
import useFetch from 'hooks/use-fetch';
import useSubmit from 'hooks/use-submit';

const ImageTiles = () => {
    const imageRatio = 1.5;
    const rows = 60;
    const columns = 60;
    var imageHeight = 0;
    var imageWidth = 0;

    if(window.innerWidth > 768){
        // desktop
        imageHeight = window.innerHeight * 0.7;
        imageWidth = imageHeight / 1.5;
    }else{
        // mobile
        imageWidth = window.innerWidth * 0.9;
        imageHeight = imageWidth * imageRatio;
    }

    const { loading, error: fetchError, data } = useFetch(`attempt/blockedTiles`);

    const { isSubmitting, submitError, response, submit } = useSubmit(
        `attempt/create`,
        'post'
      );
      
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);
    const [result, setResult] = useState('');
    const [clickedRow, setClickedRow] = useState('');
    const [clickedColumn, setClickedColumn] = useState('');

    function backgroundImgOffsetX(rowIndex){
        return (imageHeight / columns) * (rowIndex + 1);
    }

    function backgroundImgOffsetY(cellIndex){
        return (imageWidth / rows) * (cellIndex + 1);
    }

    function onCellClick(row, column){
        setOverlayVisible(true);
        setClickedRow(row);
        setClickedColumn(column);
    }

    function onSelectionCancel(){
        setOverlayVisible(false);
        setClickedRow('');
        setClickedColumn('');
    }

    function onSelectionConfirm(coordinates){
        window.FB.getLoginStatus(function(response) {
            if(response.status === 'connected'){
                submit({ 
                    fb_user_id: response?.authResponse?.userID,
                    coordinates: coordinates
                });
            }
        });
    }

    function blockTile(coordinates){
        const tile = document.getElementById(`cell-${coordinates}`);
        if(tile){
            tile.classList.add("blocked-tile");
        }
    }

    function onWin(){
        setOverlayVisible(false);
        setShowResultModal(true);
        setResult('win');
    }

    function onLose(){
        setOverlayVisible(false);
        setShowResultModal(true);
        setResult('lose');
    }
    
    useEffect(() => {
        data?.Attempts?.forEach(attempt => {
            blockTile(attempt?.coordinates);
        });
    }, [data]);

    useEffect(() => {
        if((response !== null) && !submitError){
            if(response?.isWin === true){
                onWin();
            }else{
                onLose();
            }
        }else if(submitError){
            onSelectionCancel();
        }
    }, [response]);

    useEffect(() => {
        if (submitError !== '') {
            if (submitError === 'User already played today') {
                toast.error('Já jogaste uma vez hoje. Volta amanhã para jogar novamente!');
            } else if (submitError === 'User already won a prize') {
                toast.error('Já ganhaste um prémio! Não podes jogar novamente.');
            } else {
                toast.error('Ocorreu um erro ao submeter a tua tentativa :(');
            }
        }
    }, [submitError]);

    return (
        <>
            {overlayVisible && 
                <ConfirmationOverlay 
                    isSubmitting={isSubmitting}
                    onSelectionCancel={onSelectionCancel} 
                    onSelectionConfirm={onSelectionConfirm} 
                    row={clickedRow} 
                    column={clickedColumn}/>
            }
            {showResultModal && <Modal close={() => setShowResultModal(false)} result={result}/>}
            <div className="image-tiles-container w-100">
            <p className="image-tiles-hint"><strong>Para jogar seleciona uma quadrícula. <br /> Se acertares recebes um prémio!</strong></p>
                <div 
                className="image-tiles-wrapper"
                style={{
                    width: `${imageWidth}px`,
                    height: `${imageHeight}px`
                }}>
                    <div className="grid-wrapper">
                        {Object.keys(Array(rows).fill(1)).map((row, rowIndex) => (
                            <div className="tiles-row" key={rowIndex}>
                                {Object.keys(Array(columns).fill(1)).map((cell, cellIndex) => (
                                    <div 
                                    onClick={() => onCellClick(rowIndex, cellIndex)}
                                    key={cellIndex}
                                    id={`cell-${rowIndex},${cellIndex}`}
                                    className={`
                                    cell 
                                    ${
                                        cellIndex === (columns - 1)
                                        ? 'cell-right '
                                        : ''
                                    }
                                    ${
                                        rowIndex === 0
                                        ? 'cell-top '
                                        : ''
                                    }
                                    `} 
                                    style={{
                                        backgroundSize: `${imageWidth}px ${imageHeight}px`,
                                        backgroundPosition: `bottom ${backgroundImgOffsetX(rowIndex)}px right ${backgroundImgOffsetY(cellIndex)}px`,

                                        }}>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
  );
};

export default ImageTiles;
