import api from 'utils/api';

const findUser = (userID) => new Promise( (resolve, reject) => {
  api.get('user/find/'+userID)
    .then(function (response) {
      if(response?.data?.ResultCode === 1){
        if(response?.data?.Data?.User){
          resolve();
        }else{
          reject();
        }
      }
    })
    .catch(function (err) {
      reject();
    })
});

export default findUser;