import React from 'react';
import FacebookIcon from 'components/icons/FacebookIcon';
import 'assets/css/facebook-login-button.css';

const FbLoginButton = ({onClick}) => {
  
  return(
    <div className="shake">
      <div className="element">
      <button className="facebook-login-btn" onClick={onClick}>
        <FacebookIcon className="facebook-login-btn-icon"/>
          Entrar com o Facebook
      </button>
      <p className="problem-text mt-2">*Caso tenha problemas ao iniciar sessão, por favor utilize outro navegador.</p>
      </div>
    </div>
  );
}

export default FbLoginButton;