const CAVRLogo = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="37.5"
      viewBox="0 0 260 122"
      {...props}
    >
        <path fill="currentColor" d="M147.527,83.6461q-.0016-20.0157-.0006-40.0313c0-.7519.0454-.7173-.6988-.7167-2.5294.0021-5.0591.0244-7.5877-.0155a1.4389,1.4389,0,0,0-1.4.7356q-9.8431,14.7-19.7329,29.3689c-.9441,1.4037-.9444,1.4035-2.6307,1.1439a9.2219,9.2219,0,0,1-7.8212-7.2063,12.8974,12.8974,0,0,1-.1386-4.935,10.8576,10.8576,0,0,1,7.2753-9.106,17.9477,17.9477,0,0,1,6.93-1.0788c.7412.0162.6985.06.6986-.7064q0-3.663,0-7.326c0-.789.06-.7188-.7755-.7273a30.1046,30.1046,0,0,0-7.0054.7382,22.5863,22.5863,0,0,0-9.8013,4.6978A19.9331,19.9331,0,0,0,97.8772,62.09a4.6,4.6,0,0,1-.2434,1.7812q-.022.2884-.0377.578v.9021c.0052.158.0121.316.024.4738.0063.0848.0137.1694.0213.254a3.7072,3.7072,0,0,1,.2434,1.6076c.007.0615.0156.1225.0231.1838a3.5718,3.5718,0,0,1,.0781.5633A17.4486,17.4486,0,0,0,100.19,74.663a19.7353,19.7353,0,0,0,8.8815,7.8379,31.6427,31.6427,0,0,0,11.2643,2.7906c.5662.0445.7673-.2495,1.019-.6129q7.1706-10.3536,14.3477-20.7027c.7384-1.0652.7384-1.0651,2.0212-1.0309.6074.0162.62.0161.6206.6308q.0063,5.451,0,10.9018c-.0008.66-.0141.67-.674.671-2.3549.0044-4.71.0192-7.0645-.0113a1.1252,1.1252,0,0,0-1.1669.6472c-1.4714,2.6036-2.9961,5.177-4.4937,7.7659-.1238.2141-.35.3989-.29.6893a.7091.7091,0,0,0,.5064.0843q10.8586.0033,21.7173.0027C147.57,84.3268,147.5271,84.3383,147.527,83.6461Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M207.7405,55.9868a14.62,14.62,0,0,0-6.1688-9.5641,12.1948,12.1948,0,0,0-14.502-.1961,4.8744,4.8744,0,0,0-1.2283,1.2134q-15.8859,21.755-31.7827,43.5018-11.6247,15.907-23.2428,31.8188c-.1729.237-.5076.4142-.4248.7969l-.0424.0676.05.0624h11.69c.1594-.2409.3119-.4867.4792-.7219q4.2181-5.9319,8.44-11.8613,19.4907-27.3744,38.98-54.7493a6.9651,6.9651,0,0,1,1.3282-1.4924,3.6678,3.6678,0,0,1,5.3148.7293,2.8394,2.8394,0,0,1-.6391,4.2076,6.7578,6.7578,0,0,1-2.5513,1.0711,18.648,18.648,0,0,1-5.0314.3213c-.6727-.0294-.6759-.0211-.6908.641-.01.4359-.0279.8717-.0357,1.3078q-.1157,6.54-.2294,13.0807-.0777,4.36-.1666,8.72c-.0088.401.0166.6468.5382.6421,2.5874-.0236,5.1752-.0182,7.7628-.0031.4369.0025.57-.1741.531-.573-.017-.1728-.003-.3486-.003-.5231V70.7036c0-1.0315.0023-1.0237,1.0061-1.286a17.2645,17.2645,0,0,0,7.0077-3.3631A10.1423,10.1423,0,0,0,207.7405,55.9868Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M97.8772,62.09l-.0278.0008-.0087.0631c-.0766.5211-.1512,1.0339-.1947,1.56-.0043.0523-.0082.1046-.0122.157A4.6,4.6,0,0,0,97.8772,62.09Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M97.6414,66.0791c.0356.3972.082.7925.116,1.1891.0368.14.0742.2784.1081.4175a.1379.1379,0,0,0,.0193.001A3.7072,3.7072,0,0,0,97.6414,66.0791Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M204.5933,1.2054q5.2841-.0014,10.5678-.0029c.0523.2727-.1732.4149-.2984.593Q186.0666,42.77,157.2708,83.7439a1.2112,1.2112,0,0,1-1.1348.5975c-2.064-.029-4.1287-.0093-6.1931-.0164-1.0282-.0036-.891.1026-.8892-.8712q.0185-10.1181.0484-20.2364.0333-9.5512.0872-19.1023a4.1151,4.1151,0,0,0-.0005-.4359c-.0383-.3729.1083-.55.4937-.5051a2.2818,2.2818,0,0,0,.2616.0012q4.2306,0,8.4613-.0009c.9315-.0008.792.0242.7922.8088q.0027,9.1151.001,18.23v1.0252c.3727-.13.4859-.4439.6567-.6782q12.79-17.5406,25.57-35.088,9.3437-12.8241,18.69-25.6466C204.2687,1.6149,204.4336,1.412,204.5933,1.2054Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M202.8068,45.5432h74.609c.0825.2808-.1454.4245-.2681.6014-2.6332,3.7964-5.2785,7.5843-7.9,11.3888a1.1982,1.1982,0,0,1-1.1281.5937q-28.5688-.1419-57.1379-.2536c-.7486-.0032-.7523-.0085-.8348-.6542a15.808,15.808,0,0,0-5.2847-9.9345C204.24,46.718,203.5778,46.1942,202.8068,45.5432Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M258.6408,31.4483a1.6455,1.6455,0,0,1-.3643.8116c-2.2381,3.6047-4.4957,7.1974-6.711,10.8159a1.3418,1.3418,0,0,1-1.3438.6856q-27.041-.0186-54.0819-.0057-4.2739,0-8.5478,0h-.8506c-.118-.3213.1074-.4714.235-.6449,2.7729-3.7694,5.5576-7.53,8.3187-11.3081a1.2582,1.2582,0,0,1,1.1515-.5777c8.752.0268,17.5044-.0192,26.2558.06,11.63.1051,23.2593.0491,34.8889.0959A3.4273,3.4273,0,0,1,258.6408,31.4483Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M267.9633,17.116a.8405.8405,0,0,1-.2357.7093c-2.27,3.6526-4.5569,7.2945-6.8045,10.9606a1.3372,1.3372,0,0,1-1.3235.7149c-9.3028-.0072-18.6061-.0413-27.9084.0388-11.1917.0964-22.3833.0468-33.5749.0981-.3383.0016-.6766,0-1.0189,0a.96.96,0,0,1,.318-.7668c2.7388-3.7213,5.4894-7.4338,8.2145-11.1651a1.3456,1.3456,0,0,1,1.2409-.6011c3.2558.0225,6.5119.0111,9.7679.0111h51.3246Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M207.6772,15.2891a2.3377,2.3377,0,0,1,.49-.8394c2.6006-3.6407,5.2147-7.2719,7.8036-10.9209a1.3241,1.3241,0,0,1,1.23-.6107q29.9076.0214,59.8153.015a1.4326,1.4326,0,0,1,.7624.0759c.096.2711-.1067.4411-.2216.6261-2.2836,3.6771-4.5851,7.3431-6.8483,11.0327a1.198,1.198,0,0,1-1.19.6377q-10.594-.0094-21.1882.02-19.8366.0253-39.6733.0455A2.4566,2.4566,0,0,1,207.6772,15.2891Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M199.0675,71.9654a2.43,2.43,0,0,1,.8911-.4477,21.7773,21.7773,0,0,0,2.666-1.2836,13.8649,13.8649,0,0,0,6.8482-9.04c.21-.8623.2187-.8624,1.0435-.8625q28.0024-.0006,56.005.0027a1.765,1.765,0,0,1,.7609.0535c.0626.209-.0975.328-.1858.4621-2.4106,3.6655-4.8334,7.323-7.231,10.9969a1.0864,1.0864,0,0,1-1.0515.56q-29.7028-.2133-59.4058-.4021C199.3308,72.0044,199.2535,71.9876,199.0675,71.9654Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M257.6947,74.164a1.1755,1.1755,0,0,1-.3607.733c-2.3347,3.3291-4.6857,6.6469-7.0023,9.9886a1.2763,1.2763,0,0,1-1.2055.62q-25.2411-.0233-50.4823-.0127a3.0218,3.0218,0,0,0-.3485.0018c-.3922.046-.5634-.07-.5669-.5164-.0269-3.4-.0832-6.8-.1225-10.2-.0069-.5991.01-.6.6365-.6146.1162-.0027.2325,0,.3488,0h59.1034Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M254.7237,96.3962c.6793-2.0482,1.2984-3.92,1.9212-5.7906.2535-.7611.2582-.7234,1.0158-.7455.5056-.0147.5646.2207.5621.6288-.012,1.9187-.0055,3.8374-.0056,5.7561,0,.32-.0117.64.0025.9593.0163.3648-.1875.4274-.4935.43-.3145.0029-.4836-.0756-.4811-.4361.0115-1.657.005-3.3142.0049-4.9712v-.5485c-.2744.1413-.288.384-.3566.5889-.5344,1.5962-1.0627,3.1944-1.5979,4.79-.1.297-.165.5946-.6179.5867-.4168-.0072-.5327-.2094-.6409-.55-.4832-1.52-.9918-3.0328-1.4884-4.549a1.3433,1.3433,0,0,0-.3326-.6772c-.2644.2866-.1554.5875-.1577.8584-.0119,1.4535,0,2.9072-.008,4.3607-.0027.5113-.1274.5955-.6179.5446-.2212-.023-.3071-.1217-.3107-.3254-.0015-.0872-.0014-.1744-.0014-.2616,0-2.18-.0034-4.3607.002-6.5411.0015-.6167.0131-.6167.6153-.6307.856-.02.8573-.02,1.1274.8221q.7974,2.4864,1.5935,4.9732C254.52,95.86,254.5957,96.0467,254.7237,96.3962Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M210.6706,93.4666c1.2083.869,1.502,1.7042,1.07,2.7957a1.9249,1.9249,0,0,1-1.5431,1.2514,22.7425,22.7425,0,0,1-3.5575.113c-.3372.0072-.3487-.2227-.3478-.4705.003-.7825.0011-1.565.0011-2.3475,0-1.449.0135-2.8983-.0082-4.347-.0066-.4424.1152-.6192.5841-.5979.781.0355,1.5649.0005,2.347.0176a3.9,3.9,0,0,1,.8577.1209,1.8721,1.8721,0,0,1,.9429,3.14C210.9243,93.2456,210.8137,93.3335,210.6706,93.4666Zm-2.0349,3.2054c.3178,0,.6371.02.953-.0039.8335-.0633,1.2549-.4781,1.2809-1.2314a1.2027,1.2027,0,0,0-1.1719-1.3424,7.4787,7.4787,0,0,0-1.1236-.0482c-1.274-.007-1.274-.004-1.2741,1.268C207.3,96.688,207.3,96.688,208.6357,96.672Zm-.12-3.5346a4.2134,4.2134,0,0,0,1.3212-.1136,1.02,1.02,0,0,0,.73-1.1382.9815.9815,0,0,0-.8906-1.0051,8.134,8.134,0,0,0-1.1241-.0689C207.3,90.7955,207.3,90.8,207.3,92.0129,207.3,93.1391,207.3,93.1391,208.5153,93.1374Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M336.1572,94.125a14.0842,14.0842,0,0,1,2.4562,3.3771c-1.1184.2266-1.1122.2228-1.6122-.5745-.4312-.6876-.8506-1.3852-1.3266-2.041a2.3968,2.3968,0,0,0-2.3679-.6813c-.2225.0811-.1845.2644-.1854.4279-.0044.8127.0053,1.6256-.0054,2.4383-.0069.52-.1045.5823-.6123.5623-.3046-.012-.4162-.1456-.3946-.43.0044-.0578,0-.1161,0-.1742,0-2.1771-.0036-4.3542.0024-6.5313.0016-.6079.0146-.6178.6284-.6224q1.4367-.0108,2.8738.0062a4.7087,4.7087,0,0,1,.7766.0866,1.8467,1.8467,0,0,1,1.6423,1.6532,1.9841,1.9841,0,0,1-1.1417,2.2229C336.6849,93.94,336.4657,94.0081,336.1572,94.125Zm-1.5557-3.3532,0-.0363c-.3488,0-.698.0091-1.0463-.0029-.2735-.0094-.4449.0531-.4382.3774.0124.61.0032,1.2207.0041,1.8311,0,.1857.0331.3622.27.357a12.8152,12.8152,0,0,0,2.6061-.0879,1.2238,1.2238,0,0,0-.091-2.4274A9.4822,9.4822,0,0,0,334.6015,90.7718Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M275.7854,93.1569h2.902c.1741,0,.3483-.0019.5224,0,.6975.0092.6975.01.662.9044h-1.6885c-.5225,0-1.0449-.0025-1.5673.0005-.8224.0048-.8251.0063-.8312.787-.0045.5787-.0009,1.1576-.0009,1.7278a2.86,2.86,0,0,0,1.241.1047c.9285.015,1.8575.0125,2.786,0,.3291-.0043.56.0271.5564.45-.0031.37-.1233.5084-.507.5039-1.5089-.0174-3.0183-.01-4.5274-.0052-.2811.0008-.5143-.0014-.513-.392.008-2.4333.004-4.8666.004-7.3662,1.6944,0,3.3137.0075,4.9327-.0058.365-.003.4317.1732.4364.4806.005.3274-.1071.471-.4529.4656-.9575-.0148-1.9154-.0053-2.8732-.0052-.2031,0-.4065.0079-.6094,0-.311-.0116-.4862.0891-.4766.4423C275.7973,91.8585,275.7854,92.4669,275.7854,93.1569Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M297.6156,89.8734c1.6031,0,3.2263.0069,4.8493-.0052.3739-.0028.4176.1969.4261.4939.01.3447-.1434.4543-.471.4507-1.0446-.0115-2.09-.0076-3.1343-.0036-.8009.0031-.8107.0074-.8045.8054a7.0751,7.0751,0,0,0,.063,1.5423l2.9417,0c.2322,0,.4662.0191.6961-.0033.3722-.0363.4172.166.4322.4714.0175.3562-.1352.4442-.4566.4406q-1.5236-.0173-3.0472-.0012c-.6106.0061-.6167.018-.63.6361-.01.4642.02.93-.0087,1.3926-.0271.4381.1054.6156.5783.6031,1.16-.0307,2.3216-.0008,3.4823-.0156.3537-.0045.5344.08.5349.4783,0,.4027-.19.4783-.5387.4755-1.509-.012-3.0182-.0093-4.5273-.002-.2989.0015-.493-.0391-.4908-.4137.0146-2.3794.0136-4.759.0213-7.1384A.7588.7588,0,0,1,297.6156,89.8734Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M290.1008,89.8906c1.0033,0,2.022-.0483,3.0344.0112a2.872,2.872,0,0,1,2.85,2.4643,5.6388,5.6388,0,0,1-.175,3.2606,2.7385,2.7385,0,0,1-2.4389,1.9409c-.9877.1044-1.9754.03-2.9624.0638-.294.01-.42-.108-.4151-.4058.01-.61.0025-1.221.0025-1.8316,0-1.541-.0035-3.082.003-4.6229A1.6264,1.6264,0,0,1,290.1008,89.8906Zm.857,3.7915c0,.8427.0089,1.6856-.0046,2.5281-.0051.32.0833.4918.436.4787.6095-.0227,1.221-.0045,1.8292-.0425a1.7163,1.7163,0,0,0,1.579-1.1755,4.7088,4.7088,0,0,0,.0967-3.2429,1.8164,1.8164,0,0,0-1.797-1.3884c-.5519-.0083-1.1042-.0092-1.6554-.0345-.3881-.0178-.4976.1619-.49.5233C290.97,92.1126,290.9576,92.8975,290.9578,93.6821Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M340.6214,94.0621c0,.798.0056,1.5481-.0024,2.2981-.0039.3651.2465.3252.4771.3254,1.19.0011,2.38.0094,3.57-.0042.3556-.0041.5333.0854.5208.484-.01.327-.1073.4694-.45.4672q-2.3507-.015-4.7013-.0005c-.3614.0023-.4282-.1795-.4273-.4886q.01-3.3953.0012-6.7905c-.0007-.3041.0622-.4877.4292-.4845q2.2635.02,4.5271,0c.3488-.0029.4454.141.4464.4665.001.3215-.0878.48-.4376.4764-1.0736-.0112-2.1474-.0029-3.2212-.0033-.7629,0-.7586-.05-.7255.6823.0244.5383-.254,1.2247.1378,1.5745.34.3037.9989.0786,1.5165.0888.6672.0132,1.3358.0239,2.002-.0049.38-.0164.4189.1726.4277.4726.01.3313-.0932.4552-.4469.4488C343.0773,94.0488,341.89,94.0621,340.6214,94.0621Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M218.2926,93.1569a2.0335,2.0335,0,0,1,.07.3042c.0088.1719.0026.3447.0026.6006-1.0174,0-1.9987,0-2.98,0-.2617,0-.5234.006-.785.0023-.2219-.0031-.3076.1191-.3085.32-.003.6687-.0057,1.3373-.0031,2.0059.0015.3729.29.2919.5036.2933,1.0176.0064,2.0353.0029,3.053.0031.2035,0,.4073.0077.6105,0,.3094-.0123.3939.15.3925.4322-.0015.29-.0185.5149-.4012.513q-2.355-.012-4.71.0009c-.3275.0018-.4659-.1081-.4643-.4537q.0153-3.4453.0031-6.8907c-.0006-.29.1049-.4183.4065-.4169,1.5119.0072,3.024.01,4.5358-.0029.343-.003.4461.1265.4613.4571.0185.4036-.1661.4926-.5188.4875-.9012-.0131-1.8027-.0044-2.704-.0044a4.683,4.683,0,0,1-.61-.0042c-.4827-.0638-.601.1716-.5668.6017.0434.5471-.213,1.2369.0979,1.6085.3293.3938,1.0382.1151,1.5794.1353C216.7342,93.1786,217.5134,93.1569,218.2926,93.1569Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M263.0548,97.7317a3.4759,3.4759,0,0,1-3.5246-3.285,5.4165,5.4165,0,0,1,.1229-1.9893,3.4764,3.4764,0,0,1,6.7381-.1732,4.9443,4.9443,0,0,1-.0481,3.0839A3.2918,3.2918,0,0,1,263.0548,97.7317Zm-2.5383-3.9172a7.8693,7.8693,0,0,0,.08.93,2.4721,2.4721,0,0,0,2.2288,2.1,2.38,2.38,0,0,0,2.545-1.6774,4.3511,4.3511,0,0,0-.005-2.8206,2.3568,2.3568,0,0,0-2.3629-1.6959,2.3827,2.3827,0,0,0-2.3328,1.849A6.54,6.54,0,0,0,260.5165,93.8145Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M242.7687,93.77a4.5335,4.5335,0,0,1,.2068-1.5018,3.4773,3.4773,0,0,1,6.6178-.2117,4.9028,4.9028,0,0,1-.0332,3.495,3.2822,3.2822,0,0,1-3.16,2.18,3.33,3.33,0,0,1-3.2489-2.0512A4.4088,4.4088,0,0,1,242.7687,93.77Zm6.0913.0709a3.6651,3.6651,0,0,0-.4863-2.06,2.2514,2.2514,0,0,0-2.2-1.1235,2.2172,2.2172,0,0,0-2.048,1.3756,4.3121,4.3121,0,0,0-.06,3.3066,2.4437,2.4437,0,0,0,4.5608-.11A3.4687,3.4687,0,0,0,248.86,93.8405Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M229.4418,89.9676a1.7147,1.7147,0,0,1,.9689-.0484c0,1.3935.0016,2.7544-.0009,4.1154a7.0283,7.0283,0,0,0,.1037,1.3859,1.546,1.546,0,0,0,1.5351,1.3836,3.5287,3.5287,0,0,0,.7811-.02,1.4482,1.4482,0,0,0,1.3671-1.35,9.8847,9.8847,0,0,0,.0872-1.3878c.0115-1.1894.0075-2.3791.0019-3.5686-.0016-.34-.01-.6142.4806-.6193.4554-.0047.522.191.5173.5723-.0188,1.5374.0183,3.0762-.0321,4.6124a2.4443,2.4443,0,0,1-1.8118,2.5662,4.1529,4.1529,0,0,1-2.2346-.0191,2.2171,2.2171,0,0,1-1.6841-2.094C229.3552,93.6482,229.4729,91.7939,229.4418,89.9676Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M228.6017,97.523a1.3292,1.3292,0,0,1-.67.107.4878.4878,0,0,1-.5661-.4068c-.1551-.4969-.368-.9755-.5314-1.47a.5976.5976,0,0,0-.683-.4943c-.7234.0373-1.45.0222-2.1754.0052a.4991.4991,0,0,0-.57.4c-.1236.4161-.2741.8243-.4185,1.2339-.2756.7819-.2772.7814-1.1822.65-.0752-.1484.0008-.2839.0486-.4138q1.2286-3.3426,2.463-6.683c.1245-.339.2351-.6034.7076-.5978a.7645.7645,0,0,1,.8078.59C226.7387,92.7891,227.6629,95.129,228.6017,97.523Zm-2.2979-3.14-1.2371-3.4849L223.8805,94.38A15.4314,15.4314,0,0,0,226.3038,94.3834Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M322.0784,97.5783c.1512-.42.2744-.77.4028-1.1175.7419-2.01,1.4937-4.0172,2.22-6.0333.1336-.3711.276-.5731.7231-.5727a.6978.6978,0,0,1,.7519.5361c.9193,2.37,1.8559,4.7338,2.79,7.1066-1.0389.2432-1.04.2418-1.3685-.6336-.1931-.5141-.2289-1.1866-.6379-1.4924-.4125-.3084-1.0646-.0892-1.6109-.096-.4614-.0057-1.0047-.1732-1.3646.076s-.35.8307-.5309,1.255c-.0341.08-.0535.1652-.0821.2473C323.0967,97.6406,323.0965,97.6406,322.0784,97.5783Zm2.165-3.1886a13.1391,13.1391,0,0,0,2.4365-.03l-1.2756-3.53C325.0957,92.13,324.6178,93.1816,324.2434,94.39Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M203.7055,92.6862c0-.7841.0148-1.5687-.006-2.3524-.0109-.4127.1994-.4738.54-.4667.3064.0063.4864.0643.4813.4329-.0223,1.597.0624,3.2005-.0529,4.79a2.9661,2.9661,0,0,1-4.7211,2.1929,2.3518,2.3518,0,0,1-.9894-1.671,15.3882,15.3882,0,0,1-.1138-2.6075c-.0021-.8422.0053-1.6846-.0035-2.5268-.0036-.3455-.0066-.6356.4836-.6121s.5182.0154.5218.6441c.0074,1.3069.0014,2.6139.0053,3.9209a4.4177,4.4177,0,0,0,.0828,1.0389,1.65,1.65,0,0,0,1.9346,1.3442,1.5877,1.5877,0,0,0,1.7756-1.4289,1.92,1.92,0,0,0,.0339-.3465c.0029-.784.0015-1.5679.0015-2.3519Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M345.7318,97.5481c.9082-2.4632,1.7924-4.8488,2.6639-7.239.1154-.3164.2829-.4806.6319-.4388.2728.0326.5683-.0763.7174.3129.9317,2.431,1.8822,4.8549,2.8241,7.282.0081.0208-.0164.0542-.0257.0817-.9653.1478-.948.1408-1.3-.7284-.2039-.5028-.2107-1.1768-.661-1.4651-.4132-.2644-1.0173-.0851-1.5358-.077-.4848.0076-1.0515-.19-1.4336.1121-.3444.2721-.3451.8353-.516,1.263-.0322.0807-.0607.1629-.09.2447C346.73,97.67,346.73,97.67,345.7318,97.5481Zm4.6082-3.15-1.2423-3.5019c-.4092,1.1992-.7918,2.3208-1.1949,3.5019Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M185.2917,93.6919c.0208-.29.0274-.581.0646-.8685a3.4131,3.4131,0,0,1,4.2874-2.9708,2.76,2.76,0,0,1,1.9447,1.7213c.0783.1828.1793.4392-.1169.4907-.2473.0429-.5417.3276-.7756-.0379a1.8948,1.8948,0,0,1-.111-.2357,1.8823,1.8823,0,0,0-2.0519-1.1334,2.0748,2.0748,0,0,0-1.924,1.3971,4.69,4.69,0,0,0,.0082,3.4082,2.209,2.209,0,0,0,4.0735.0664c.1023-.2144-.0129-.6425.4859-.5578.6594.1119.7359.2169.4707.8467a2.86,2.86,0,0,1-2.6609,1.91,3.0393,3.0393,0,0,1-3.1718-1.6769A5.2256,5.2256,0,0,1,185.2917,93.6919Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M306.59,89.9378c.9949-.0907.9968-.0913,1.2629.6807q.991,2.8749,1.9788,5.75c.32.0156.262-.26.3212-.4242.6382-1.7711,1.2663-3.5458,1.9-5.3186.2868-.8025.29-.8015,1.171-.6726.0788.2516-.0851.4512-.1628.66-.79,2.1168-1.5969,4.2273-2.3911,6.3425-.133.3542-.1942.6878-.7247.6945-.51.0063-.6243-.2634-.7642-.6438-.82-2.2291-1.6579-4.4513-2.4878-6.6766C306.6544,90.2245,306.6356,90.1121,306.59,89.9378Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M238.7943,94.1971c0-.8989-.0022-1.7979.0008-2.6968.0024-.7373.0185-.6946-.7278-.6923-.406.0012-.8126-.0133-1.2178.0044-.3634.0158-.5227-.1067-.5134-.4966.0083-.3481.15-.4481.4728-.4464q2.5229.0127,5.0457,0c.3861-.0025.4.2174.4021.5042.0022.2764-.07.4427-.3841.4357-.4348-.01-.87-.0039-1.3049,0-.7605.006-.7662.0073-.7675.7394-.0033,1.8268.0033,3.6537-.0034,5.4806-.0022.5838-.06.614-.5628.606-.3443-.0056-.4478-.1571-.444-.48C238.8016,96.1691,238.7942,95.183,238.7943,94.1971Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M317.0663,93.7605c0-1.1335.0109-2.2671-.0064-3.4-.0057-.3771.1254-.4954.5017-.4989.4041-.0038.4841.1733.482.5239-.01,1.7147-.0047,3.43-.0047,5.1442a5.9906,5.9906,0,0,1-.0033.61c-.0421.4084.1031.57.5354.5556.8709-.0291,1.7436-.0158,2.6154-.0057.4676.0054.5406.1093.5112.5658-.0154.2384-.0871.3775-.3573.3762q-1.9619-.0095-3.9237-.0023c-.34.0009-.3516-.22-.3512-.468Q317.0685,95.4607,317.0663,93.7605Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M281.6684,93.76c0-1.1316.0109-2.2633-.0063-3.3945-.0057-.3748.122-.5064.5-.5021.3624.0042.5212.1059.5182.4938-.0135,1.7407-.0059,3.4816-.0059,5.2225,0,.203.016.4076-.003.6089-.0348.3668.0944.5158.4839.5052.87-.0237,1.7417.0169,2.6108-.0173.4555-.0179.5984.048.5668.6157-.0114.2056-.1054.3307-.3147.3313-1.3346.0038-2.6693.0018-4.0039.0042-.338.0006-.3473-.2258-.3468-.4731Q281.6709,95.4572,281.6684,93.76Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M193.0275,93.7194c0-1.102.0043-2.2041-.0026-3.3061-.002-.3179.0121-.5565.4469-.5514.3953.0047.573.0947.5683.5349-.02,1.8559-.0107,3.7121-.0072,5.5682.0013.7113.0074.716.6914.72.8121.005,1.6243.0117,2.4361-.0039.3495-.0067.5377.0748.5377.4766s-.191.48-.539.4763c-1.189-.0127-2.3787-.026-3.567.0049-.4842.0126-.5822-.1808-.5727-.6138C193.0437,95.9239,193.0275,94.8215,193.0275,93.7194Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M357.8981,96.7279q-.0051.43-.01.859c-1.335.0139-2.6705.02-4.0055.0472-.3837.0078-.4953-.1474-.4933-.5151.0119-2.2374.0151-4.4749-.0022-6.7122-.0033-.4246.1381-.5486.5527-.546s.4721.2.4706.5393q-.0124,2.7894-.0027,5.5791c.0013.698.0075.6974.7049.7074Q356.5053,96.7064,357.8981,96.7279Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M315.3616,93.766c0,1.1048-.0047,2.21.003,3.3145.0022.3185-.0067.56-.4438.5594-.41-.0009-.5623-.1249-.5591-.5512.0165-2.2386.0129-4.4775.0028-6.7162-.0016-.37.1219-.509.504-.51.38-.0013.5051.1274.4993.5021C315.3506,91.498,315.3616,92.6321,315.3616,93.766Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M264.2292,88.0181c-.3215.4273-.6261.84-.94,1.2459a.577.577,0,0,1-.56.1567c-.1762-.0141-.268-.0986-.1847-.2829.1312-.29.2854-.5712.3973-.8684a.46.46,0,0,1,.525-.343A1.8738,1.8738,0,0,1,264.2292,88.0181Z" transform="translate(-97.5961 -1.2025)"/>
        <path fill="currentColor" d="M273.7676,89.932c-.853-.0785-.8584-.0805-1.12.6549q-.99,2.7823-1.9723,5.568a.39.39,0,0,1-.3448.0056q-.9445-2.7516-1.89-5.504c-.2892-.8409-.2911-.84-1.2229-.7033-.0686.28.1062.5045.1917.7414.4126,1.1439.8481,2.28,1.2737,3.4189.375,1.0036.7648,2.0022,1.1128,3.0151a.6828.6828,0,0,0,.7683.5142c.4255.0023.5282-.2509.6442-.5618q1.23-3.2963,2.4688-6.5892C273.7438,90.3136,273.9059,90.1445,273.7676,89.932Z" transform="translate(-97.5961 -1.2025)"/>
    </svg>
  );

  export default CAVRLogo;