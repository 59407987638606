import React from 'react';
import 'assets/css/footer.css';

const Footer = () => {
    return (
        <div className="footer-powered-by-container">
          <div className="footer-powered-by-container-text">
            <span className="footer-powered-by-text">Powered by&nbsp;</span>
            <a 
              className="footer-purple-profile-text" 
              href={process.env.REACT_APP_PURPLEPROFILE_URL} 
              target="_blank"
              rel="noreferrer"
              >
                Purple Profile
            </a>
          </div>
        </div>
    )
}

export default Footer;