import React from 'react';
import 'assets/css/sponsors-box.css';
import PRacingFuelsLogo  from 'components/icons/P1RacingFuelsLogo';
import CooperTiresLogo from 'components/icons/CooperTiresLogo';
import ChampionsLubesLogo from 'components/icons/ChampionsLubesLogo';
import CMMontalegreLogo from 'components/icons/CMMontalegreLogo';
import CAVRLogo from 'components/icons/CAVRLogo';
import FPAKLogo from 'components/icons/FPAKLogo';
import CiMontalegreLogo from 'components/icons/CiMontalegreLogo';

const SponsorsBox = () => {
    return (
        <div className="sponsors-sidebar-box-container">
            <div className="title-sponsors-sidebar-box-title m-3">
                    <span>Title Sponsor</span>
                </div>
                <div className="sponsors-sidebar-container m-3">
                    <div className="sponsors-grid-row one">
                        <a href={process.env.REACT_APP_COOPERTIRES_URL} rel="noreferrer" target="_blank">
                            <CooperTiresLogo className="sidebar-tires"/>
                        </a>
                    </div>
                </div>
                <div className="sponsors-sidebar-box-title m-3">
                    <span>Sponsors</span>
                </div>
                <div className="sponsors-sidebar-container m-3">
                    <div className="sponsors-grid-row two">
                        <a href={process.env.REACT_APP_P1FUELS_URL} rel="noreferrer" target="_blank">
                            <PRacingFuelsLogo className="sidebar-fuels" />
                        </a>
                        <a href={process.env.REACT_APP_CHAMPIONLUBES_URL} rel="noreferrer" target="_blank">
                            <ChampionsLubesLogo className="sidebar-lubes" />
                        </a>
                    </div>
                </div>
                <div className="supports-sidebar-box-title m-3">
                    <span>Apoios</span>
                </div>
                <div className="supports-sidebar-container m-3">
                    <div className="supports-grid-row one">
                        <a href={process.env.REACT_APP_CM_MONTALEGRE_URL} rel="noreferrer" target="_blank">
                            <CMMontalegreLogo className="sidebar-cm-montalegre" />
                        </a>
                        <a href={process.env.REACT_APP_CAVR_URL} rel="noreferrer" target="_blank">
                            <CAVRLogo className="sidebar-cavr"/>
                        </a>
                    </div>
                    <div className="supports-grid-row two">
                        <a href={process.env.REACT_APP_FPAK_URL} rel="noreferrer" target="_blank">
                            <FPAKLogo className="sidebar-fpak" />
                        </a>
                        <a href={process.env.REACT_APP_CI_MONTALEGRE_URL} rel="noreferrer" target="_blank">
                            <CiMontalegreLogo className="sidebar-circuit" />
                        </a>
                    </div>
                </div>
        </div>
    )
}

export default SponsorsBox;