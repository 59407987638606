import api from 'utils/api';

const giveawayDetails = (giveawayID) => new Promise( (resolve, reject) => {
  api.get('giveaway/details/'+giveawayID)
    .then(function (response) {
      if(response?.data?.ResultCode === 1){
        resolve(response);
      }else{
        reject(response);
      }
    })
    .catch(function (err) {
      reject(err);
    })
});

export default giveawayDetails;