import React from 'react';
import ImageTiles from 'components/ImageTiles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {
  Row,
  Col,
} from 'reactstrap';
import FbLoginButton from 'components/FbLoginButton';
import SponsorsBox from 'components/SponsorsBox';
import SponsorsBoxVertical from 'components/SponsorsBoxVertical';
import 'assets/css/main.css';
import findUser from 'requests/findUser';
import registerUser from 'requests/registerUser';
import giveawayDetails from 'requests/giveawayDetails';
import Spinner from 'components/Spinner';

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginStatus: null,
      giveawayIsActive: false,
      isFetchingGiveawayStatus: true,
    };
  }

  componentDidMount(){
    this.fetchGiveawayDetails();
    var self = this;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referall = urlParams.get('refer');

    window.FB.getLoginStatus(function(response) {
      self.setState({ loginStatus: response?.status })
      if(response?.status === 'connected'){
        window.FB.api('/me?fields=id,name,email&access_token='+response?.authResponse?.accessToken, function(resp) {
          findUser(resp?.id).then(() => {
        }).catch(() => {
            registerUser(resp?.id, resp?.name, resp?.email, referall);
          })
        });
      }else{
        localStorage.removeItem('userID');
        localStorage.removeItem('loginStatus');
      }
    });
  }

  fetchGiveawayDetails = () => {
    giveawayDetails(1).then((resp) => {
      this.setState({giveawayIsActive: resp?.data?.Data?.isActive});
    }).catch((err) => {
      this.setState({giveawayIsActive: false});
    }).finally(() => {
      this.setState({isFetchingGiveawayStatus: false});
    })
  }

  handleLogin = () => {
    var self = this;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referall = urlParams.get('refer');

    window.FB.login(function(response) {
      localStorage.setItem('userID', response?.authResponse?.userID);
      localStorage.setItem('loginStatus', response?.status);

      window.FB.api('/me?fields=id,name,email&access_token='+response?.authResponse?.accessToken, function(resp) {
        findUser(resp?.id).then(() => {
        }).catch(() => {
          registerUser(resp?.id, resp?.name, resp?.email, referall)
        })
      });

      self.setState({ loginStatus: response?.status })

    }, {scope: 'public_profile,email'});
  }

  handleLogout = () => {
    var self = this;
    window.FB.logout(function(response) {

      localStorage.removeItem('userID');
      localStorage.removeItem('loginStatus');

      self.setState({ loginStatus: response?.status })
    });
  }

  render(){
    const loginStatus = localStorage.getItem('loginStatus');
    const { giveawayIsActive, isFetchingGiveawayStatus } = this.state;
    if(isFetchingGiveawayStatus) return(
      <Spinner />
    );

    if (!giveawayIsActive) return (
      <>
        <Header loginStatus={loginStatus} handleLogout={this.handleLogout}/>
        <div id="giveaway-over-container">
          <p>Este passatempo já terminou :( <br />
          Se não ganhaste nenhum prémio sabes que podes sempre comprar o teu bilhete em <a href="https://montalegre.bol.pt/">montalegre.bol.pt</a></p>
        </div>
        <Footer/>
      </>
    );
    return(
      <Col sm="12" style={{ height: '100vh' }}>
        <Row style={{height: '8%'}}>
          <Header loginStatus={loginStatus} handleLogout={this.handleLogout}/>
        </Row>

        <Row className="main-container align-items-center justify-content-center" >
          {window.innerWidth > 768 && 
            <Col sm="4" className="h-100">
              <SponsorsBox />
            </Col>
          }
          {!isFetchingGiveawayStatus &&
            <Col sm="8" className="align-items-center justify-content-center">
              {loginStatus === 'connected'
                ?
                <div className="h-100 w-100 align-items-start justify-content-center">
                  <ImageTiles />
                </div>
                :
                <div className="fb-login-container align-items-center justify-content-center">
                  <span>Para jogar por favor faz login</span>
                  <div className="fb-login-button">
                    <FbLoginButton onClick={this.handleLogin}/>
                  </div>
                </div>
              }
            </Col>
          }
          {window.innerWidth <= 768 && 
            <Col>
              <SponsorsBoxVertical />
            </Col>
          }
        </Row>

        <Row>
          <Footer />
        </Row>
      </Col>
    );
  }
}

export default Main;