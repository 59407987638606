import { useState, useEffect } from 'react';
import api from 'utils/api';

const useFetch = (url) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);

  const fetchData = () => {
    setLoading(true);
    api
      .get(url)
      .then(function (response) {
        if (response?.data?.ResultCode === 1) {
          setData(response?.data?.Data);
          setError('');
        } else if (response?.data?.Result === 'Error') {
          setError(response?.data?.Error);
        } else {
          setError('Unknown error');
        }
      })
      .catch(function (err) {
        setError(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!url) return;
    fetchData();
  }, [url]);

  return { data, loading, error };
};

export default useFetch;