import api from 'utils/api';

const registerUser = (fb_user_id, name, email, referall) => new Promise( (resolve, reject) => {
  api.post('user/create', {
    fb_user_id: fb_user_id,
    name: name,
    email: email,
    referall: referall
  })
    .then(function (response) {
      if(response?.data?.ResultCode === 1){
        if(response?.data?.Data?.User){
          resolve();
        }else{
          reject();
        }
      }
    })
    .catch(function (err) {
      reject();
    })
});

export default registerUser;