import React from 'react';
import 'assets/css/modal.css';
import Confetti from 'react-confetti';

const Modal = ({ close, result }) => {
  const userID = localStorage.getItem('userID');
  const share = () => {
    window.FB.ui({
      method: 'share',
      display: 'popup',
      href: `${process.env.REACT_APP_SITE_URL}?refer=${userID}`,
      hashtag: '#montalegrerx',
      quote: 'Passatempo FIA WRX Montalegre 2021 - Participa e ganha prémios!'
    }, function(response){});
  }
  return(
    <div className="my-modal-backdrop">
      {result === 'win' &&
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
        />
      }
      <div className={`my-modal ${result === 'win' ? 'my-modal-win' : 'my-modal-lose'}`}>
        <div className="my-modal-header">
          <div className="close-button"><span onClick={close}>X</span></div>
        </div>
        <div className="my-modal-body">
          {result === 'win'
            ?
              <>
                <p className="title-font congrats">Parabéns :)</p>
                <p>Ganhaste um prémio! Enviamos-te um email com mais informações.</p>
                <p>Partilha com os seus amigos para poderes reclamar o teu prémio!</p>
              </>
            :
              <>
                <p className="title-font">Não foi desta :(</p>
                <p>Mas não desistas! Amanhã podes tentar de novo!</p>
                <p>Partilha com os seus amigos! Se eles ganharem, tu também vais receber um prémio!</p>
                <p>Não te esqueças que podes sempre comprar o bilhete em <a href="https://montalegre.bol.pt/">montalegre.bol.pt</a></p>
              </>
          }
        </div>
        <div className="my-modal-footer">
          <div className="my-modal-share-container">
            <button onClick={share}>Partilhar</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;