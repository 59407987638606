import React from 'react';
import 'assets/css/sponsors-box-vertical.css';
import PRacingFuelsLogo  from 'components/icons/P1RacingFuelsLogo';
import CooperTiresLogo from 'components/icons/CooperTiresLogo';
import ChampionsLubesLogo from 'components/icons/ChampionsLubesLogo';
import CMMontalegreLogo from 'components/icons/CMMontalegreLogo';
import CAVRLogo from 'components/icons/CAVRLogo';
import FPAKLogo from 'components/icons/FPAKLogo';
import CiMontalegreLogo from 'components/icons/CiMontalegreLogo';

const SponsorsBoxVertical = () => {
    return (
        <div className="box-vertical-container">
            <div className="sponsors-vertical-grid-container">
                <div className="vertical-title-sponsors" id="title-sponsor-vertical">
                    <span>Title Sponsor</span>
                </div>
                <div className="vertical-sponsors-logos-container mb-3">
                    <div className="sponsors-vertical-grid-row one">
                        <a id="fuels" href={process.env.REACT_APP_P1FUELS_URL} rel="noreferrer" target="_blank"> 
                            <CooperTiresLogo />
                        </a>
                    </div>
                </div>
            </div>
            <div className="sponsors-vertical-grid-container mt-3">
                <div className="vertical-title-sponsors">
                    <span>Sponsors</span>
                </div>
                <div className="vertical-sponsors-logos-container">
                    <div className="sponsors-vertical-grid-row two">
                        <a id="tires" href={process.env.REACT_APP_COOPERTIRES_URL} rel="noreferrer" target="_blank">
                            <ChampionsLubesLogo />
                        </a>
                        <a id="lubes" href={process.env.REACT_APP_CHAMPIONLUBES_URL} rel="noreferrer" target="_blank">
                        <PRacingFuelsLogo  />
                        </a>
                    </div>
                </div>
            </div>
            <div className="supports-vertical-grid-container">
                <div className="vertical-title-supports">
                        <span>Apoios</span>
                </div>

                <div className="vertical-supports-logos-container">
                    <div className="supports-vertical-grid-row one">
                        <a href={process.env.REACT_APP_CM_MONTALEGRE_URL} rel="noreferrer" target="_blank">
                            <CMMontalegreLogo />
                        </a>
                        <a href={process.env.REACT_APP_CAVR_URL} rel="noreferrer" target="_blank">
                            <CAVRLogo />
                        </a>
                    </div>
                    <div className="supports-vertical-grid-row two">
                        <a href={process.env.REACT_APP_FPAK_URL} rel="noreferrer" target="_blank">
                            <FPAKLogo />
                        </a>
                        <a href={process.env.REACT_APP_CI_MONTALEGRE_URL} rel="noreferrer" target="_blank">
                            <CiMontalegreLogo />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SponsorsBoxVertical;