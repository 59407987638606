import React, { useState } from 'react';
import 'assets/css/header.css';
import Logo from 'assets/img/fia-wrx-logo.svg';
import LogoutIcon from 'components/LogoutIcon';
import Rules from './Rules';

const Header = ({loginStatus, handleLogout}) => {

    const [navbarOpen, setNavbarOpen] = useState(false)

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
      }

    return (
        <>
        <header className="header-container">
            <div className="header-logo-container">
                <a href={process.env.REACT_APP_FIAWREX_URL} rel="noreferrer" target="_blank">
                    <img src={Logo} alt="world-rx"></img>
                </a>
            </div>
            {window.innerWidth > 860 &&
                <div className="header-title-container h-100">
                    <p className="title-font header-title">GIVEAWAY - Cooper Tires World RX of Montalegre</p>
                </div>
            }
            
            <div className="header-buttons-container">
                <div className="header-sidebar-button-container">
                    <button onClick={handleToggle} title="Regulamento">
                        <span>{navbarOpen ? "Fechar" : "Regulamento"}</span>
                    </button>
                </div>
                <div className="header-button-logout-container">
                    {loginStatus === 'connected' && 
                        <button onClick={handleLogout} title="Terminar Sessão">
                            <LogoutIcon />
                        </button>
                    }   
                </div>
            </div>
            
        </header>
        <div className={`sidebar ${navbarOpen ? " showSidebar" : "hideSidebar"}`}>
            <Rules />
        </div>
        </>
    )
}

export default Header;