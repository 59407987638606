import React from "react";
import 'assets/css/confirmation-overlay.css';
import TireTrack from 'components/icons/TireTrackIcon';

const ConfirmationOverlay = ({ onSelectionCancel, onSelectionConfirm, row, column, isSubmitting }) => {
  return(
    <div className="confirmation-overlay-wrapper-backdrop">
      <div className="confirmation-overlay-wrapper">
        <p className="confirmation-text">
          Selecionaste a célula {row}-{column}.<br />Tens a certeza?
        </p>
        <div className="confirmation-buttons-wrapper">
          <button className="my-btn my-btn-confirm" disabled={isSubmitting} onClick={() => onSelectionConfirm(`${row},${column}`)}>
            Confirmar
          </button>
          <button className="my-btn my-btn-cancel" disabled={isSubmitting} onClick={onSelectionCancel}>Alterar</button>
        </div>
        <TireTrack className="tire-track"/>
      </div>
    </div> 
  );
}

export default ConfirmationOverlay;