import Main from 'components/Main';
import { ToastContainer } from 'react-toastify';
import FacebookProvider from 'components/FacebookProvider';
import 'assets/css/app.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <FacebookProvider>
      <ToastContainer />
      <Main />
    </FacebookProvider>
  );
}

export default App;
